.footer {
  background-color: #f5f5f5;
  padding: 2rem 0;
  margin-top: 4rem;
  border-top: 1px solid #eaeaea;
}

.footer-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
}

.footer-section {
  width: 100%;
}

.copyright, .terms {
  color: #666;
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.5;
}

.footer-link {
  color: #0066cc;
  text-decoration: none;
  transition: color 0.2s ease;
}

.footer-link:hover {
  color: #004499;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }

  .footer-section {
    width: auto;
  }
}