.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.portfolio-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.section {
  margin-bottom: 2rem;
}

.paper {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.divider {
  margin: 2rem 0;
  border: none;
  border-top: 1px solid #eaeaea;
}

.experience-item {
  margin-bottom: 2rem;
}

.subtitle {
  color: #666;
  margin-bottom: 1.5rem;
}

.date {
  color: #666;
  font-size: 0.9rem;
}

.company {
  color: #333;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.description {
  line-height: 1.6;
}
